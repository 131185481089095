export const encodeForm = (data) => {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
		.join("&");
};

export const encodeFormWithFormData = (data) => {
	const formData = new FormData();

	for (const key of Object.keys(data)) {
		formData.append(key, data[key]);
	}

	return formData;
};
